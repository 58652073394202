export const titleContainerStyles = {
	alignItems: 'center',
	justifyContent: 'center',
	paddingX: { xs: '1.25rem', md: '2%', lg: '5%', xl: '6rem' },
	textAlign: 'center',
	textWrap: 'pretty',
};

export const titleStyles = {
	paddingTop: { xs: '2.5rem', md: '3.75rem' },
	paddingBottom: { xs: '2.5rem', md: '.75rem' },
};
