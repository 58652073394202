import { Box, Button, Typography, Grid } from '@mui/material';

import { BlogDetailsPage } from '@/types/generated';

import BlogListItem from '../BlogListItem';

import { blogListContainerStyles, blogListItemContainerStyles, showMoreBoxStyles } from './BlogListPageDefault.styles';

export interface IBlogListPageDefaultProps {
	handleShowMoreClick: () => void;
	totalBlogs: number;
	displayShowMoreButton: boolean;
	blogsList: Array<BlogDetailsPage>;
	buttonLabel: string;
	loadMoreButtonLabel: string;
}

const BlogListPageDefault = ({
	handleShowMoreClick,
	totalBlogs,
	displayShowMoreButton,
	blogsList,
	buttonLabel,
	loadMoreButtonLabel,
}: IBlogListPageDefaultProps) => {
	return (
		<Grid container sx={blogListContainerStyles} data-test-id="blog_list_container">
			<Grid container sx={blogListItemContainerStyles} data-test-id="blog_list_items_container">
				{blogsList?.map(
					(item, index) => item && <BlogListItem blogDetails={item} buttonLabel={buttonLabel} key={index} />
				)}
			</Grid>
			{displayShowMoreButton && (
				<Box sx={showMoreBoxStyles} data-test-id="blog_list_show_more_container">
					<Typography variant="bodyLargeBook">
						Showing {blogsList.length} of {totalBlogs}
					</Typography>
					<Button
						size="L"
						variant="primaryDefault"
						onClick={handleShowMoreClick}
						data-test-id="blog_list_show_more_button"
					>
						{loadMoreButtonLabel}
					</Button>
				</Box>
			)}
		</Grid>
	);
};

export default BlogListPageDefault;
