import { Grid, Typography } from '@mui/material';

import { BlogList as BlogListDetails, Brand } from '@/types/generated';

import BlogList from '../BlogList/BlogList';

import { titleContainerStyles, titleStyles } from './BlogListPage.styles';

export interface IBlogListPage {
	blogList: BlogListDetails;
	brand: Brand;
	title?: string;
	preview?: boolean;
}

export default function BlogListPage({ blogList, title, preview = false }: IBlogListPage) {
	return (
		<>
			{title && (
				<Grid container sx={titleContainerStyles}>
					<Typography
						component="h1"
						variant="heroMedium"
						color="text.primary"
						data-test-id="blog_list_page_title"
						sx={titleStyles}
					>
						{title}
					</Typography>
				</Grid>
			)}
			<BlogList {...blogList} preview={preview} />
		</>
	);
}
