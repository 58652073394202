import { getGraphQLClientForContentful } from '@/graphql/client';
import { logWarning } from '@/utils/miscUtils';
import { BlogsListByBrandDocument } from '@/graphql/__generated/sdk';
import { BlogListResponse } from '@/components/BlogList/BlogList';

export const getBlogListByBrand = async (
	limit: number,
	skip: number,
	brandName: string,
	url: string,
	preview: boolean
) => {
	try {
		if (!brandName) {
			return {} as BlogListResponse;
		}

		const gqlClient = getGraphQLClientForContentful(url, preview);

		if (gqlClient === null) {
			logWarning('GQL_CLIENT_IS_NULL: missing token for fetching contentful data');
			throw new Error('gqlClient is null');
		}

		const response = await gqlClient
			.query(BlogsListByBrandDocument, {
				limit,
				skip,
				brandName,
				order: ['date_DESC'],
				preview,
			})
			.toPromise();

		return (response.data || {}) as BlogListResponse;
	} catch (err) {
		logWarning('Error fetching blogs list by brand');
	}
};
