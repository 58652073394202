import { Fragment, useEffect, useRef, useState } from 'react';

import { Box, Button, useMediaQuery, useTheme } from '@mui/material';

import { BlogDetailsPage, BlogList as TBlogList } from '@/types/generated';
import { useAppContext } from '@/context/AppContextProvider.ctx';
import TAGLink from '@/components/TAGLink';

import BlogListItem from '../BlogListItem';
import FeaturedBlog from '../FeaturedBlog/FeaturedBlog';

import {
	desktopFeatureBlogStyles,
	halfScreenContainerStyles,
	mobileFeatureBlogStyles,
	topBlogsStyles,
	viewMoreButtonBoxStyles,
	viewMoreButtonStyles,
} from './BlogHalfScreen.styles';

export interface IBlogsHalfScreenProps extends Pick<TBlogList, 'displayType'> {
	blogsList: Array<BlogDetailsPage>;
	buttonLabel: string;
	loadMoreButtonLabel: string;
}

const BlogsHalfScreen = ({ blogsList, displayType, buttonLabel, loadMoreButtonLabel }: IBlogsHalfScreenProps) => {
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const featuredBlogRef = useRef<HTMLDivElement>(null);
	const [containerHeight, setContainerHeight] = useState('');

	const { config } = useAppContext();
	const blogRootPath = config.features.blogs.root;

	useEffect(() => {
		if (featuredBlogRef.current?.clientHeight && !isMobileScreen) {
			setContainerHeight(`${featuredBlogRef.current.clientHeight}px`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobileScreen, featuredBlogRef.current]);
	const additionalBlogs = isMobileScreen
		? blogsList.slice(1, 5) // Items from index 1 to 4 for mobile
		: blogsList.slice(1);
	const featuredBlog = blogsList[0];
	return (
		<Box
			sx={halfScreenContainerStyles(containerHeight, displayType)}
			data-test-id={displayType ? `${displayType}_container` : 'default_container'}
		>
			{isMobileScreen && (
				// Featured blog section for mobile
				<Box data-test-id={'featured_blog_in_mobile'} sx={mobileFeatureBlogStyles}>
					{featuredBlog && <FeaturedBlog blogDetails={featuredBlog} />}
				</Box>
			)}
			<Box sx={topBlogsStyles}>
				{additionalBlogs?.map((item, index) => {
					return (
						item && (
							<Fragment key={index}>
								<BlogListItem blogDetails={item} buttonLabel={buttonLabel} />
								{index === additionalBlogs.length - 1 && (
									<Box sx={viewMoreButtonBoxStyles}>
										<TAGLink href={blogRootPath} dataTestId={`blog_root_link_${blogRootPath}`}>
											<Button size="M" variant="primaryDefault" sx={viewMoreButtonStyles}>
												{loadMoreButtonLabel}
											</Button>
										</TAGLink>
									</Box>
								)}
							</Fragment>
						)
					);
				})}
			</Box>
			{!isMobileScreen && (
				// Featured blog section for non-mobile
				<Box data-test-id={'featured_blog_in_desktop'} ref={featuredBlogRef} sx={desktopFeatureBlogStyles}>
					{featuredBlog && <FeaturedBlog blogDetails={featuredBlog} />}
				</Box>
			)}
		</Box>
	);
};

export default BlogsHalfScreen;
