export const getNumberOfBlogs = (displayType: string) => {
	switch (displayType) {
		case 'Blog List Page':
			return 12;
		case 'One Row':
			return 4;
		case 'Half Screen Left':
		case 'Half Screen Right':
			return 7;
		default:
			return 12;
	}
};
