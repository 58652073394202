import { useEffect, useState } from 'react';

import { getBlogListByBrand } from '@/services/getBlogListByBrand';
import { BlogDetailsPage, BlogDetailsPageCollection, BlogList as TBlogList } from '@/types/generated';
import { logWarning } from '@/utils/miscUtils';
import { useAppContext } from '@/context/AppContextProvider.ctx';
import { getNumberOfBlogs } from '@/utils';

import BlogListPageDefault from './BlogListPageDefault/BlogListPageDefault';
import BlogsHalfScreen from './BlogsHalfScreen/BlogsHalfScreen';

export type BlogListResponse = {
	blogDetailsPageCollection: BlogDetailsPageCollection;
	total: number;
	skip: number;
	limit: number;
};

export interface IBlogList extends TBlogList {
	/**
	 * Used while fetching the blog list.
	 *
	 * @type {Maybe<Boolean>}
	 * @memberof IBlogList
	 */
	preview?: boolean;
}

export default function BlogList({ displayType, brand, buttonLabel, loadMoreButtonLabel, preview = false }: IBlogList) {
	const numberOfBlogs = getNumberOfBlogs(displayType as string);

	const [state, setState] = useState<{
		startIndex: number;
		totalBlogs: number;
		displayShowMoreButton: boolean;
		blogsList: Array<BlogDetailsPage>;
	}>({
		startIndex: 0,
		totalBlogs: 0,
		blogsList: [],
		displayShowMoreButton: false,
	});

	const { environment, config } = useAppContext();
	const contentfulGraphqlURL =
		environment === 'prod'
			? config.services.prod.CONTENTFUL_GRAPHQL_URL
			: config.services.nonprod.CONTENTFUL_GRAPHQL_URL;

	// brand name comes from blog list content itself or take it from context
	const brandNameForQuery = brand?.title || config.name;

	useEffect(() => {
		getBlogListByBrand(numberOfBlogs, state.startIndex, brandNameForQuery, contentfulGraphqlURL, preview)
			.then((blogsListResponse: BlogListResponse | undefined) => {
				if (blogsListResponse) {
					const blogDetailsList = state.blogsList.concat(
						blogsListResponse.blogDetailsPageCollection?.items as Array<BlogDetailsPage>
					);
					setState({
						...state,
						blogsList: blogDetailsList,
						displayShowMoreButton:
							displayType === 'Blog List Page' &&
							blogDetailsList.length < blogsListResponse.blogDetailsPageCollection?.total,
						totalBlogs: blogsListResponse.blogDetailsPageCollection?.total,
					});
				}
				return;
			})
			.catch((error) => {
				// Handle any errors that occurred during the API call.
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				logWarning(`Error fetching blogs list by brand error: ${error}`);
				setState({
					...state,
					blogsList: [],
					displayShowMoreButton: false,
					totalBlogs: 0,
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.startIndex]);

	const handleShowMoreClick = () => {
		setState({
			...state,
			startIndex: state.startIndex + numberOfBlogs,
		});
	};
	switch (displayType) {
		case 'Blog List Page':
		case 'One Row':
			return (
				<BlogListPageDefault
					totalBlogs={state.totalBlogs}
					blogsList={state.blogsList}
					buttonLabel={buttonLabel as string}
					loadMoreButtonLabel={loadMoreButtonLabel as string}
					displayShowMoreButton={state.displayShowMoreButton}
					handleShowMoreClick={handleShowMoreClick}
				/>
			);
		case 'Half Screen Left':
		case 'Half Screen Right':
			return (
				<BlogsHalfScreen
					displayType={displayType}
					blogsList={state.blogsList}
					buttonLabel={buttonLabel as string}
					loadMoreButtonLabel={loadMoreButtonLabel as string}
				/>
			);
		default:
			return (
				<BlogListPageDefault
					totalBlogs={state.totalBlogs}
					blogsList={state.blogsList}
					buttonLabel={buttonLabel as string}
					loadMoreButtonLabel={loadMoreButtonLabel as string}
					displayShowMoreButton={state.displayShowMoreButton}
					handleShowMoreClick={handleShowMoreClick}
				/>
			);
	}
}
