import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';

import FocalPointImage from '@/components/FocalPointImage';
import { BlogImage } from '@/components/BlogDetailsPage';
import TAGLink from '@/components/TAGLink';
import { useAppContext } from '@/context/AppContextProvider.ctx';
import { ImageAsset as TImageAsset, BlogDetailsPage } from '@/types/generated';
import ImageAsset from '@/components/ImageAsset';
import { IImage } from '@/components/ImageAsset/ImageAsset';

import { getBlogItemImageStyles } from '../BlogList.styles';

import {
	featuredBlogBoxStyles,
	featuredBlogContainerStyles,
	featuredBlogImageStyles,
	featuredBlogImageWrapperStyles,
	featuredButtonStyles,
	textAndButtonOverlayStyles,
} from './FeaturedBlog.styles';

interface IBlogListItemProps {
	blogDetails: BlogDetailsPage;
}

const FeaturedBlog = ({ blogDetails }: IBlogListItemProps) => {
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const { config } = useAppContext();

	const title = blogDetails.title || '';
	const path = blogDetails.path || '';
	const blogRootPath = config.features.blogs.root;

	const blogItemLink = `${blogRootPath}${path}/`;

	const blogImage = blogDetails.blogImage as BlogImage;

	const imageAsset = blogDetails.imageAsset as TImageAsset;

	return (
		<Box width={'100%'} data-test-id="featured_blog_container" sx={featuredBlogContainerStyles}>
			<TAGLink
				href={blogItemLink}
				title={title}
				linkSx={featuredBlogBoxStyles}
				dataTestId={`featured_blog_link_${path}`}
			>
				<Box sx={featuredBlogImageWrapperStyles}>
					{imageAsset ? (
						<ImageAsset
							desktopImage={imageAsset?.desktopImage as IImage}
							mobileImage={imageAsset?.mobileImage as IImage}
							imageSx={getBlogItemImageStyles}
							dataTestId={`featured_blog_img_asset_focal_point_image_${path}`}
						/>
					) : (
						<FocalPointImage
							brandFolderImage={blogImage?.image}
							focalPoint={blogImage?.focalPoint}
							imageSx={featuredBlogImageStyles}
							dataTestId={`featured_blog_focal_point_image_${path}`}
						/>
					)}
					{/* Text and Button Overlay */}
					<Box sx={textAndButtonOverlayStyles}>
						<Typography variant={'overline'} color={'text.light'}>
							{blogDetails.category?.name}
						</Typography>
						<Typography variant={'header1'} color={'text.light'}>
							{title}
						</Typography>
						<Button
							size={isMobileScreen ? 'M' : 'S'}
							variant="secondaryHC"
							sx={featuredButtonStyles}
							tabIndex={-1}
							aria-label={`Learn More about ${title}`}
						>
							Learn more
						</Button>
					</Box>
				</Box>
			</TAGLink>
		</Box>
	);
};

export default FeaturedBlog;
