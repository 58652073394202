import { SxProps, useMediaQuery, useTheme } from '@mui/material';

import FocalPointImage, { BrandFolderImage, FocalPointData } from '@/components/FocalPointImage';

export interface IImage {
	image: BrandFolderImage;
	focalPoint: FocalPointData;
}

export interface IImageAsset {
	/**
	 * Required field for the Desktop Image
	 *
	 * @type {IImage}
	 * @memberof IImageAsset
	 */
	desktopImage: IImage;
	/**
	 * Optional field for the Mobile Image
	 *
	 * @type {IImage}
	 * @memberof IImageAsset
	 */
	mobileImage?: IImage;
	/**
	 * Whether to display the image as a background image.
	 *
	 * @type {boolean}
	 * @memberof IImageAsset
	 * @todo rename this to isBackgroundImage
	 */
	isBackgroundImage?: boolean;
	/**
	 * Optional styles to apply to the image.
	 *
	 * @type {SxProps}
	 * @memberof IImageAsset
	 */
	imageSx?: SxProps;
	/**
	 * Optional data-test-id attribute for testing purposes
	 *
	 * @type {string}
	 * @memberof IImageAsset
	 */
	dataTestId?: string;
}

export default function ImageAsset({ desktopImage, mobileImage, isBackgroundImage, imageSx, dataTestId }: IImageAsset) {
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const imageToDisplay = isMobileScreen && mobileImage?.image ? mobileImage : desktopImage;

	return (
		<FocalPointImage
			brandFolderImage={imageToDisplay?.image}
			focalPoint={imageToDisplay?.focalPoint}
			backgroundImage={isBackgroundImage}
			imageSx={imageSx}
			dataTestId={dataTestId}
		/>
	);
}
