export const blogListContainerStyles = {
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	paddingX: { xs: '1.25rem', md: '2%', lg: '5%', xl: '6rem' },
	paddingY: { xs: '1.25rem', md: '1rem', lg: '1.75rem' },
};

export const blogListItemContainerStyles = {
	display: 'grid',
	gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)' },
	gridColumnGap: { xs: '.75rem', lg: '1.25rem' },
	gridRowGap: { xs: '1.75rem', lg: '2.5rem' },
	width: '100%',
};

export const showMoreBoxStyles = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	marginTop: '3rem',
	gap: '.75rem',
};
