import { Maybe } from '@/types/generated';

export const halfScreenContainerStyles = (containerHeight: string, displayType: Maybe<string> | undefined) => {
	return {
		display: 'flex',
		flexDirection: { xs: 'column', sm: displayType && displayType === 'Half Screen Right' ? 'row-reverse' : 'row' },
		height: { xs: '100%', sm: containerHeight },
		alignItems: 'flex-start',
		flexShrink: '0',
		position: 'relative',
		overflowY: 'hidden',
		gap: { xs: '1.25rem', sm: '0' },
	};
};

export const mobileFeatureBlogStyles = {
	display: 'flex',
	alignItems: 'flex-start',
	alignSelf: 'stretch',
};

export const desktopFeatureBlogStyles = {
	display: 'flex',
	alignItems: 'flex-start',
	flex: '1 0 0',
};

export const topBlogsStyles = {
	display: 'grid',
	gridTemplateColumns: '1fr 1fr',
	overflowY: { sm: 'auto' },
	padding: { xs: '0px 1.25rem', lg: '0px 2.5rem' },
	rowGap: { xs: '1.75rem', lg: '2.5rem' },
	columnGap: { xs: '.75rem', lg: '1.25rem' },
	flex: '1 0 0',
	alignSelf: 'stretch',
};

export const viewMoreButtonBoxStyles = {
	gridColumn: '1 / -1', // This will make the box span all columns (from the first to the last)
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
};

export const viewMoreButtonStyles = { width: 'fit-content' };
