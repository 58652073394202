import { Typography, Grid, useTheme, useMediaQuery, Box } from '@mui/material';

import { BlogDetailsPage, ImageAsset as TImageAsset } from '@/types/generated';
import TAGLink from '@/components/TAGLink';
import RichText, { RichTextContent } from '@/components/RichText';
import FocalPointImage from '@/components/FocalPointImage';
import { BlogImage } from '@/components/BlogDetailsPage';
import { useAppContext } from '@/context/AppContextProvider.ctx';
import ImageAsset from '@/components/ImageAsset';
import { IImage } from '@/components/ImageAsset/ImageAsset';

import TAGButton from '../TAGButton';

import {
	blogItemBoxStyles,
	blogItemButtonStyles,
	blogItemContentStyles,
	getBlogItemImageStyles,
	getBlogItemImageWrapperStyles,
} from './BlogList.styles';

export interface IBlogListItemProps {
	blogDetails: BlogDetailsPage;
	buttonLabel: string;
}

export default function BlogListItem({ blogDetails, buttonLabel }: IBlogListItemProps) {
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const { config } = useAppContext();

	const title = blogDetails.title || '';
	const path = blogDetails.path || '';
	const blogRootPath = config.features.blogs.root;

	const blogItemLink = `${blogRootPath}${path}/`;

	const blogImage = blogDetails.blogImage as BlogImage;

	const imageAsset = blogDetails.imageAsset as TImageAsset;

	return (
		<Grid item width={'100%'} data-test-id="blog_list_item_container">
			<TAGLink
				href={blogItemLink}
				title={title}
				linkSx={blogItemBoxStyles}
				dataTestId={`blog_list_item_link_${path}`}
			>
				<Box sx={getBlogItemImageWrapperStyles}>
					{imageAsset ? (
						<ImageAsset
							desktopImage={imageAsset?.desktopImage as IImage}
							mobileImage={imageAsset?.mobileImage as IImage}
							imageSx={getBlogItemImageStyles}
							dataTestId={`blog_list_item_img_asset_focal_point_image_${path}`}
						/>
					) : (
						<FocalPointImage
							brandFolderImage={blogImage?.image}
							focalPoint={blogImage?.focalPoint}
							imageSx={getBlogItemImageStyles}
							dataTestId={`blog_list_item_focal_point_image_${path}`}
						/>
					)}
				</Box>
				<Grid item sx={blogItemContentStyles} data-test-id="blog_list_item_content_wrapper">
					<Typography variant={isMobileScreen ? 'bodySmallBook' : 'bodyMediumBook'} color={'text.primary'}>
						{blogDetails.category?.name} | {blogDetails.timeToRead} min read
					</Typography>
					<Typography variant={isMobileScreen ? 'header3' : 'header4'}>{title}</Typography>
					{!isMobileScreen && (
						<RichText
							content={blogDetails.summary?.json as RichTextContent}
							docProps={{ color: 'text.secondary', variant: 'bodyLargeBook', textAlign: 'left' }}
						/>
					)}
				</Grid>
				<TAGButton
					size={isMobileScreen ? 'M' : 'S'}
					variant="secondaryDefault"
					sx={blogItemButtonStyles}
					tabIndex={-1}
					aria-label={`Learn More about ${title}`}
				>
					{buttonLabel}
				</TAGButton>
			</TAGLink>
		</Grid>
	);
}
