export const featuredBlogContainerStyles = { height: { xs: '100%', md: null } };

export const featuredBlogImageWrapperStyles = {
	width: '100%',
	position: 'relative',
	height: { xs: '29.313rem', sm: '100vh' },
};

export const featuredBlogImageStyles = { aspectRatio: '4/5' };

export const textAndButtonOverlayStyles = {
	background: 'linear-gradient(180deg, rgba(51, 51, 51, 0.00) 0%, #333 100%)',
	position: 'absolute',
	bottom: 0,
	padding: { xs: '1.25rem', md: '1.25rem 2.5rem 2.5rem 2.5rem' }, // Padding inside the overlay container
	width: '100%',
	display: 'flex',
	alignItems: 'flex-start',
	flexDirection: 'column',
	gap: { xs: '.5rem', md: '1.25rem' }, // Ensures the overlay matches the image width
};

export const featuredButtonStyles = { width: 'fit-content' };

export const featuredBlogBoxStyles = {
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	height: '100%',
};
