export const getBlogItemImageWrapperStyles = {
	width: '100%',
};

export const getBlogItemImageStyles = {
	aspectRatio: '4/5',
};

export const blogItemBoxStyles = {
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	height: '100%',
};

export const blogItemContentStyles = {
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	marginTop: '1.25rem',
	marginBottom: '2rem',
	gap: '.5rem',
};

export const blogItemButtonStyles = {
	marginTop: 'auto',
	width: 'fit-content',
};
